import { NavigationGuard } from "vue-router";
import { store } from "@/store";
import { IUser, AssignmentType, IEnvironment } from "@/models";

export const assignmentGuard: NavigationGuard = async (to, from, next) => {
    if (store.getters["app/isAuthenticated"]) {
        if (to.matched.some(route => route.meta.requiredAssignments) || to.matched.some(route => route.meta.excludedAssignments)) {
            const user: IUser = store.getters["app/currentUser"];
            const environment: IEnvironment = store.getters["app/activeEnvironment"];
            const requiredAssignments: AssignmentType[] = to.matched.find(route => route.meta.requiredAssignments)?.meta.requiredAssignments;
            const excludedAssignments: AssignmentType[] = to.matched.find(route => route.meta.excludedAssignments)?.meta.excludedAssignments;
            const isGranted = !to.matched.some(route => route.meta.requiredAssignments) ||
                user?.userAssignments?.some(a => requiredAssignments.some(r => r === a.assignmentType && a.environmentId === environment?.environmentId));
            const isExcluded = to.matched.some(route => route.meta.excludedAssignments) &&
                user?.userAssignments?.some(a => excludedAssignments.some(r => r === a.assignmentType && a.environmentId === environment?.environmentId));
            if (isGranted && !isExcluded) {
                next();
            } else {
                next("/unauthorized");
            }
        } else {
            next();
        }
    } else {
        // If user is not yet authenticated, let auth guard do the login.
        next();
    }
};
