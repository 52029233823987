import Vue from "vue";
import { ActionTree } from "vuex";
import * as Msal from "msal";
import { IAppState } from "./state";
import { GET_ENVIRONMENTS, GET_SINGLE_USER, LOGOUT, SET_ACTIVE_ENVIRONMENT_ID } from "./mutations-types";
import { authService, AxiosService } from "@/services";
import { IUser, IEnvironment } from "@/models";
import { AxiosRequestConfig } from "axios";

export const actions: ActionTree<IAppState, any> = {
    async getEnvironments(context): Promise<void> {
        let environments: IEnvironment[] = [];
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IEnvironment[]>(`${process.env.VUE_APP_WEB_API_BASE_URL}/environments/`, config);
            environments = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_ENVIRONMENTS, environments);
        }
    },

    async setActiveEnvironmentId(context, environmentId): Promise<void> {
        context.commit(SET_ACTIVE_ENVIRONMENT_ID, environmentId);
    },

    async getSingleUser(context): Promise<void> {
        let user: IUser = null;

        // Exceptions are caught by the AuthGuard.
        try {
            context.state.appReady = false;
            const userAccount: Msal.Account = context.state.userAccount;
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get(`${process.env.VUE_APP_WEB_API_BASE_URL}/users/${userAccount?.accountIdentifier}`, config);
            user = response.data;
            context.commit(GET_ENVIRONMENTS, [{
                environmentId: user.defaultEnvironmentId,
                code: user.defaultEnvironmentCode,
                label: user.defaultEnvironmentDisplayName,
            }]);

            context.commit(SET_ACTIVE_ENVIRONMENT_ID, user.defaultEnvironmentId);

        } finally {
            context.state.appReady = true;
            context.commit(GET_SINGLE_USER, user);
        }
    },

    async login(): Promise<void> {
        if (!authService.getAccount()) {
            authService.login();
        }
    },

    async logout(context): Promise<void> {
        authService.logout();
        context.commit(LOGOUT);
    },
};
