import { IRequestDevStepExternal, IDocument } from "@/models";
import { AxiosService } from "@/services";
import { AxiosRequestConfig } from "axios";
import Vue from "vue";

export class RequestDevStepService {
    public static get fileExtensions(): string {
        return ".pdf,.doc,.docx,.xls,.xlsx,.zip";
    }

    public static async getSingleExternalRequestDevStep(requestId: number, requestDevStepId: number): Promise<IRequestDevStepExternal> {
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IRequestDevStepExternal>(`${process.env.VUE_APP_WEB_API_BASE_URL}/external/requests/${requestId}/devSteps/${requestDevStepId}`, config);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    public static async updateExternalRequestDevStep(requestDevStep: IRequestDevStepExternal): Promise<IRequestDevStepExternal> {
        try {
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const url = `${process.env.VUE_APP_WEB_API_BASE_URL}/external/requests/${requestDevStep.requestId}/devSteps/${requestDevStep.requestDevStepId}`;
            const response = await Vue.axios.put<IRequestDevStepExternal>(url, requestDevStep, config);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    public static async uploadDocument(document: IDocument): Promise<IDocument> {
        try {
            const formData: FormData = new FormData();
            formData.append("document", document?.file);
            formData.append("documentType", document?.documentType?.toString());
            formData.append("requestDevStepId", document?.requestDevStepId?.toString());
            const url = `${process.env.VUE_APP_WEB_API_BASE_URL}/requests/${document?.requestId}/documents`;
            const config: AxiosRequestConfig = await AxiosService.getAxiosFormDataConfiguration();
            return await Vue.axios.post(url, formData, config);
        } catch {
            return null;
        }
    }
}
