export enum AssignmentType {
    Administrator = 1,
    MarketingManager = 2,
    Supply = 3,
    SupplyCreationValidator = 4,
    SupplyDocumentValidator = 5,
    LegalValidator = 6,
    Development = 7,
    Supplier = 8,
    Repro = 9,
    Controller = 10,
    Coordinator = 11,
    WarehouseManager = 12,
}
