import Vue from "vue";
import Vuex from "vuex";
import { app } from "./app";
import { requests } from "./requests";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        app,
        requests,
    },
});

export { store };
