import * as Msal from "msal";
import { authService } from "@/services";
import { IUser, IEnvironment } from "@/models";

interface IAppState {
    activeEnvironmentId: number;
    userAccount: Msal.Account;
    appReady: boolean;
    environments: IEnvironment[];
    users: IUser[];
    usersLoaded: boolean;
}

const initialState: IAppState = {
    activeEnvironmentId: null,
    userAccount: authService.getAccount(),
    appReady: true,
    environments: [],
    users: [],
    usersLoaded: false,
};

export { IAppState, initialState };
