import { NavigationGuard } from "vue-router";
import { store } from "@/store";

export const authGuard: NavigationGuard = async (to, from, next) => {
    if (!store.getters["app/isAuthenticated"]) {
        await store.dispatch("app/login");
        next();
    } else {
        if (to.matched.some(route => route.meta.requiresAuth) && !store.getters["app/currentUser"]) {
            try {
                await store.dispatch("app/getSingleUser");
                next();
            } catch (ex) {
                if (ex.response?.status === 401) {
                    next("/unauthorized");
                } else {
                    next({ name: "error", params: { error: ex.message } });
                }
            }
        } else {
            next();
        }
    }
};
