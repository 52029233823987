interface Array<T> {
    sortByProperty(prop: (v: any) => string): T[];
}

Array.prototype.sortByProperty = function(prop: (v: any) => string): any[] {
    return this.sort((a, b) => {
        const source = prop(a)?.toLocaleLowerCase().trim();
        const target = prop(b)?.toLocaleLowerCase().trim();
        if (source && target) {
            return source.localeCompare(target);
        } else if (source) {
            return 1;
        } else if (target) {
            return -1;
        } else {
            return 0;
        }
    });
};
