import { ActionTree } from "vuex";
import Vue from "vue";
import { IRequestsState } from "./state";
import { GET_SINGLE_DEV_STEP_EXTERNAL, GET_DEV_STEP_EXTERNALS, SET_ACTIVE_DEV_STEP_EXTERNAL_ID, RESET_STATE } from "./mutations-types";
import { AxiosService, RequestDevStepService, NotificationService } from "@/services";
import { IRequestDevStepExternalsPage, IRequestDevStepExternal } from "@/models";
import { AxiosRequestConfig } from "axios";

export const actions: ActionTree<IRequestsState, any> = {
    async getDevStepExternals(context, parameters: [string, string][]): Promise<void> {
        let requestsPage: IRequestDevStepExternalsPage = null;
        try {
            const query = AxiosService.buildQueryParameters(parameters);
            const config: AxiosRequestConfig = await AxiosService.getAxiosConfiguration();
            const response = await Vue.axios.get<IRequestDevStepExternalsPage>(`${process.env.VUE_APP_WEB_API_BASE_URL}/external/requests${query}`, config);
            requestsPage = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(GET_DEV_STEP_EXTERNALS, requestsPage);
        }
    },

    async getSingleDevStepExternal(context, ids: [number, number]): Promise<void> {
        // Force mutation when user wants to refresh the same request.
        if (ids[1] === context.state.activeDevStepExternalId){
            context.commit(SET_ACTIVE_DEV_STEP_EXTERNAL_ID, null);
        }

        let response: IRequestDevStepExternal;
        try {
            response = await RequestDevStepService.getSingleExternalRequestDevStep(ids[0], ids[1]);
            if (response) {
                context.commit(GET_SINGLE_DEV_STEP_EXTERNAL, response);
            }
        } catch (e) {
            console.error(e);
        } finally {
            context.commit(SET_ACTIVE_DEV_STEP_EXTERNAL_ID, response?.requestDevStepId);
        }
    },

    async updateDevStepExternal(context, devStepExternal: IRequestDevStepExternal): Promise<IRequestDevStepExternal> {
        let response: IRequestDevStepExternal = null;
        try {
            response = await RequestDevStepService.updateExternalRequestDevStep(devStepExternal);
            NotificationService.showSuccess("Requête sauvegardée");

            // Fetch requests to retrieve api updates.
            const query: [string, string][] = [];
            query.push(["environment", context.rootGetters["app/activeEnvironment"]?.code]);
            query.push(["userId", String(context.rootGetters["app/currentUser"]?.userId ?? 0)]);
            query.push(["limit", String(context.state.limit)]);
            query.push(["page", String(context.state.page)]);
            query.push(["archives", String(context.state.archives)]);
            if (context.state.itemCode?.length > 0){
                query.push(["itemCode", context.state.itemCode]);
            }

            context.dispatch("getDevStepExternals", query);
            return response;
        } catch {
            return response;
        }
    },

    clearState(context): void {
        context.commit(RESET_STATE);
    },
};
