import { authService } from "@/services";
import { AxiosRequestConfig } from "axios";

export class AxiosService {
    public static async getAxiosConfiguration(): Promise<AxiosRequestConfig> {
        return {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${await authService.getAccessToken()}`,
            },
        };
    }

    public static async getAxiosFormDataConfiguration(): Promise<AxiosRequestConfig> {
        return {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${await authService.getAccessToken()}`,
            },
        };
    }

    public static buildQueryParameters(parameters: [string, string][]): string {
        let queryString = "";
        if (parameters?.length > 0) {
            for (const param of parameters) {
                queryString += `${queryString.length > 0 ? "&" : "?"}${param[0]}=${param[1]}`;
            }
        } else {
            return "";
        }

        return queryString;
    }
}
