import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
    name: "error-view",
})
export default class ErrorView extends Vue {
    @Prop(String)
    public error: string;
}
