import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { LoaderSpinner, UserDefinedCodeSelect } from "@/components";
import { RequestDevStepService, NotificationService } from "@/services";
import { IRequestDevStepExternal, RequestStatus, IUserAssignment, AssignmentType, DocumentType, IDocument } from "@/models";
import { validations } from "./validations";

@Component({
    name: "upload-development-document",
    components: { LoaderSpinner, UserDefinedCodeSelect },
    validations,
})
export default class UploadDevelopmentDocument extends Vue {
    @Prop(Number)
    public requestId: number;

    @Prop(Number)
    public requestDevStepId: number;

    public vm: IRequestDevStepExternal;
    public loadingData: boolean;
    public pendingAction: boolean;

    private promises: Promise<any>[];

    public constructor() {
        super();

        this.vm = null;
        this.loadingData = false;
        this.pendingAction = false;
        this.promises = [];
    }

    public get readonly(): boolean {
        const requestStatusConstraint: boolean = this.vm?.requestStatus < RequestStatus.DevelopmentDocument || this.vm?.requestStatus === RequestStatus.Canceled;
        const requestDevStepStatusConstraint: boolean = this.vm?.requestDevStepStatus !== RequestStatus.DevelopmentDocumentPending;
        const supplierConstraint: boolean = !this.vm?.needContactRepro && this.userNotBelongToSupplier(this.vm?.supplierCode);
        const assignmentConstraint: boolean = (this.vm?.needContactRepro && this.userIsNot(AssignmentType.Repro)) ||
            (!this.vm?.needContactRepro && this.userIsNot(AssignmentType.Supplier));

        return requestStatusConstraint || requestDevStepStatusConstraint || supplierConstraint || assignmentConstraint;
    }

    public get quality(): string {
        return this.getDisplayText(this.vm.qualityCode, this.vm.qualityLabel);
    }

    public get bottling(): string {
        return this.getDisplayText(this.vm.bottlingCode, this.vm.bottlingLabel);
    }

    public get packaging(): string {
        return this.getDisplayText(this.vm.packagingCode, this.vm.packagingLabel);
    }

    public get fileExtensions(): string {
        return RequestDevStepService.fileExtensions;
    }

    private get activeEnvironmentCode(): string {
        return this.$store.getters["app/activeEnvironment"]?.code;
    }

    public async updateDevelopmentDocument(event: any): Promise<void> {
        if (event.target.files.length > 0) {
            this.vm.developmentDocument = {
                documentId: null,
                documentType: DocumentType.Development,
                requestId: this.vm?.requestId,
                requestDevStepId: this.vm?.requestDevStepId,
                fileKey: null,
                fileName: event.target.files[0].name,
                fileUri: null,
                uploadDate: null,
                uploaderId: null,
                uploaderName: null,
                uploaderEmail: null,
                file: event.target.files[0],
                thumbnail: null,
            };

            this.$v.vm.developmentDocument.$touch();
        }
    }

    public async submitRequestDevStep(): Promise<void> {
        this.pendingAction = true;
        if (this.validateBeforeSubmit()) {
            const currentStatus = this.vm.requestDevStepStatus;
            const document: IDocument = await RequestDevStepService.uploadDocument(this.vm.developmentDocument);
            if (document) {
                this.vm.requestDevStepStatus = RequestStatus.DevelopmentDocumentValidation;
                const response: IRequestDevStepExternal = await this.$store.dispatch("requests/updateDevStepExternal", this.vm);
                if (response) {
                    NotificationService.showSuccess("Document uploaded");
                    this.vm = response;
                    this.$router.push("/");
                } else {
                    this.vm.requestDevStepStatus = currentStatus;
                }
            }
        } else {
            NotificationService.showError("Form contains errors, please review then try again");
        }

        this.pendingAction = false;
    }

    // Hook.
    public async created(): Promise<void> {
        this.loadingData = true;
        await this.getRequestDevStep();
        this.loadingData = false;
    }

    private async getRequestDevStep(): Promise<void> {
        await this.$store.dispatch("requests/getSingleDevStepExternal", [this.requestId, this.requestDevStepId]);

        this.vm = ({...this.$store.getters["requests/activeDevStep"]});
        if (this.vm?.environmentId &&
            this.vm.environmentId !== this.$store.getters["app/activeEnvironment"]?.environmentId) {
            await this.$store.dispatch("app/setActiveEnvironmentId", this.vm?.environmentId);
        }

        if (this.vm && this.vm.requestStatus >= RequestStatus.DevelopmentDocument ) {
            if (this.vm.requestDevStepStatus < RequestStatus.DevelopmentDocumentValidation) {
                this.vm.developmentDocument = null;
                this.vm.technicalDocuments = [];
            }
        } else {
            this.$router.push({name: "not-found"});
        }
    }

    private userIsNot(value: AssignmentType): boolean{
        return !this.$store.getters["app/currentUser"]?.userAssignments?.some((a: IUserAssignment) =>
            a.environmentCode === this.activeEnvironmentCode &&
            a.assignmentType === value);
    }

    private userNotBelongToSupplier(supplierCode: string): boolean{
        if (supplierCode) {
            return !this.$store.getters["app/currentUser"]?.userAssignments?.some((a: IUserAssignment) =>
                a.environmentCode === this.activeEnvironmentCode &&
                a.assignmentType === AssignmentType.Supplier && a.roleCode?.trimEquals(supplierCode) === true);
        } else {
            return true;
        }
    }

    private validateBeforeSubmit(): boolean {
        this.$v.vm.$touch();
        return !this.$v.vm.$invalid;
    }

    private getDisplayText(code: string, label: string): string {
        return `${code?.trim() ?? ""} - ${label?.trim() ?? ""}`;
    }
}
