import { IRequestDevStepExternal, RequestStatus } from "@/models";

interface IRequestsState {
    activeDevStepExternalId: number;
    devStepsLoaded: boolean;
    devSteps: IRequestDevStepExternal[];
    limit: number;
    page: number;
    totalPages: number;
    archives: boolean;
    itemCode: string;
    requestStatus: RequestStatus;
}

const getInitialState = (): IRequestsState => ({
    activeDevStepExternalId: null,
    devStepsLoaded: false,
    devSteps: [],
    limit: 20,
    page: 1,
    totalPages: 0,
    archives: false,
    itemCode: null,
    requestStatus: null,
});

export { IRequestsState, getInitialState };
