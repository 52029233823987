import { II18nMessage } from "@/models";

class I18nService {
    private locale: string;

    public constructor(locale?: string) {
        if (Object.keys(messages).some(m => m.localeCompare(locale?.toLocaleLowerCase()) === 0)) {
            this.locale = locale;
        } else {
            this.locale = "fr";
        }
    }

    public get message(): II18nMessage {
        return messages[this.locale]?.message;
    }
}

const messages = {
    fr: {
        message: {
            browserWarning: "Votre navigateur n'est pas compatible avec l'application. Merci d'utiliser Chrome, Firefox ou Safari.",
            dashboard: "Tableau de bord",
            filterBy: "Filtrer par",
            itemCode: "Code article",
            logout: "Se déconnecter",
            status: "Statut",
            welcome: "Bienvenue",
        }
    },
    en: {
        message: {
            browserWarning: "Your browser is not supported by this application. Please use Chrome Browser, Firefox or Safari instead.",
            dashboard: "Dashboard",
            filterBy: "Filter by",
            itemCode: "Item code",
            logout: "Logout",
            status: "Status",
            welcome: "Welcome",
        }
    },
};

export { I18nService };
