import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { RequestStatus, IRequestDevStepExternal, AssignmentType, IUser, IEnvironment } from "@/models";
import { LoaderSpinner } from "@/components";
import Paginate from "vuejs-paginate";
import vSelect from "vue-select";
import { I18nService } from "@/services";

@Component({
    name: "home-view",
    components: { LoaderSpinner, Paginate, "vue-select": vSelect },
})
export default class HomeView extends Vue {
    @Prop(({ default: false }))
    public archives: boolean;

    public loadingData: boolean;

    private i18nService: I18nService;

    public constructor() {
        super();

        this.loadingData = false;
        const lang = this.user?.userAssignments.some(a => a.assignmentType === AssignmentType.Repro || a.assignmentType === AssignmentType.Supplier) ? "en" : "fr";
        this.i18nService = new I18nService(lang);
    }

    public get user(): IUser {
        return this.$store.getters["app/currentUser"];
    }

    public get title(): string {
        return this.archives ? "Archives" : this.i18nService.message.dashboard;
    }

    public get devStep(): IRequestDevStepExternal[] {
        return this.$store.state.requests.devSteps;
    }

    public get page(): number {
        return this.$store.state.requests.page;
    }

    public set page(value: number) {
        this.$store.commit("requests/SET_ACTIVE_PAGE", value);
    }

    public get totalPages(): number {
        return this.$store.state.requests.totalPages;
    }

    public get itemCodeFilter(): string {
        return this.$store.state.requests.itemCode;
    }

    public set itemCodeFilter(value: string) {
        this.$store.commit("requests/SET_ITEM_CODE", value);
    }

    public get activeEnvironment(): IEnvironment {
        return this.$store.getters["app/activeEnvironment"];
    }

    // Hook.
    @Watch("archives")
    public async onArchivesChanges(newVal: boolean, oldVal: boolean): Promise<void> {
        this.loadingData = true;
        this.page = 1;
        this.itemCodeFilter = null;
        await this.getDevStepExternals();
        this.loadingData = false;
    }

    @Watch("activeEnvironment")
    public async onEnvironmentChanges(newVal: IEnvironment, oldVal: IEnvironment): Promise<void> {
        if (newVal?.environmentId !== oldVal?.environmentId) {
            this.loadingData = true;
            await this.loadData();
            this.loadingData = false;
        }
    }

    public async created(): Promise<void> {
        this.loadingData = true;
        await this.loadData();
        this.loadingData = false;
    }

    public getRequestNumber(devStep: IRequestDevStepExternal): string {
        return devStep?.newItemCode ?? "---";
    }

    public getDevelopmentDocumentLabel(requestDevStep: IRequestDevStepExternal): string {
        if (requestDevStep.needContactRepro) {
            return "Repro";
        } else {
            return requestDevStep.supplierCode + "-" + requestDevStep.supplierLabel;
        }
    }

    public isRequestLate(devStep: IRequestDevStepExternal): string {
        if (devStep?.statusTimeOutDate) {
            const statusOfRequest = new Date(devStep?.statusTimeOutDate);
            const today = new Date();

            if ( today > statusOfRequest  ) {
                return "late";
            }
        }
    }

    public getRequestSupplierLineClass(devStep: IRequestDevStepExternal): string {
        if (devStep?.requestDevStepStatus === RequestStatus.TechnicalDocumentValidated ||
            devStep?.requestDevStepStatus >= RequestStatus.MarketValidationValidated && !devStep?.needTechnicalDocuments) {
            if (devStep?.needTechnicalDocuments === false) {
                return "valid-supplier no-repro";
            }
            return "valid-supplier";
        } else if (devStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentPending ||
            devStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentRejected ||
            devStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentValidated && devStep?.needMarketValidation ||
            devStep?.requestDevStepStatus === RequestStatus.MarketValidationPending ||
            devStep?.requestDevStepStatus === RequestStatus.MarketValidationExpired ||
            devStep?.requestDevStepStatus === RequestStatus.MarketValidationRejected) {
            return "pending-step-1";
        } else if (devStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentValidation) {
            return "pending-step-1 pending-validation";
        } else if (devStep?.needTechnicalDocuments && (devStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentValidated||
            devStep?.requestDevStepStatus === RequestStatus.MarketValidationValidated)) {
            return "pending-step-2";
        } else if (devStep?.requestDevStepStatus === RequestStatus.TechnicalDocumentValidation && devStep?.needTechnicalDocuments) {
            return "pending-step-2 pending-validation";
        } else {
            return "";
        }
    }

    public isWaitingStep1(devStep: IRequestDevStepExternal): boolean {
        return devStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentValidation ||
            devStep?.requestDevStepStatus === RequestStatus.DevelopmentDocumentValidated && devStep?.needMarketValidation ||
            devStep?.requestDevStepStatus === RequestStatus.MarketValidationPending;
    }

    public isWaitingStep2(devStep: IRequestDevStepExternal): boolean {
        return devStep?.requestDevStepStatus === RequestStatus.TechnicalDocumentValidation && devStep?.needTechnicalDocuments;
    }

    public navigateToPage(page: number) {
        this.page = page;
        this.getDevStepExternals();
    }

    public getDevStepExternals(): Promise<void> {
        const query: [string, string][] = [];
        query.push(["environment", this.activeEnvironment?.code]);
        query.push(["userId", String(this.user?.userId ?? 0)]);
        query.push(["limit", String(this.$store.state.requests.limit)]);
        query.push(["page", String(this.page)]);
        query.push(["archives", String(this.archives)]);
        if (this.itemCodeFilter?.length > 0) {
            query.push(["itemCode", this.itemCodeFilter]);
        }

        return this.$store.dispatch("requests/getDevStepExternals", query);
    }

    private async loadData(): Promise<void> {
        const promises: Promise<any>[] = [];
        if (!this.$store.state.requests.devStepsLoaded || this.archives !== this.$store.state.requests.archives) {
            promises.push(this.getDevStepExternals());
        }

        await Promise.all(promises);
    }
}
