export enum RequestStatus {
    Draft = 1,
    RequestValidation = 10,
    RequestRejected = 20,
    RequestValidated = 30,
    CodeCreation = 40,
    CodeNotCreated = 50,
    CodeCreated = 60,
    ExecutionDocumentRequest = 65,
    ExecutionDocumentRequestRejected = 67,
    ExecutionDocumentValidation = 70,
    ExecutionDocumentValidated = 80,
    DevelopmentDocumentRequest = 85,
    DevelopmentDocument = 90,
    DevelopmentDocumentPending = 100,
    DevelopmentDocumentExpired = 105,
    DevelopmentDocumentValidation = 110,
    DevelopmentDocumentRejected = 115,
    DevelopmentDocumentValidated = 120,
    MarketValidationPending = 122,
    MarketValidationExpired = 124,
    MarketValidationRejected = 126,
    MarketValidationValidated = 128,
    TechnicalDocumentValidation = 130,
    TechnicalDocumentValidated = 140,
    TechnicalData = 150,
    Development = 170,
    ControlNotCorrect = 180,
    Completed = 190,
    Canceled = 200,
}
