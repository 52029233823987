import { MutationTree } from "vuex";
import { GET_SINGLE_USER, GET_USERS, LOGOUT, GET_ENVIRONMENTS, SET_ACTIVE_ENVIRONMENT_ID } from "./mutations-types";
import { IAppState } from "./state";
import { IUser, IEnvironment } from "@/models";

export const mutations: MutationTree<IAppState> = {
    [GET_ENVIRONMENTS](state, payload: IEnvironment[]): void {
        if (payload) {
            if (!state.environments) {
                state.environments = [];
            }

            for(const environment of payload)
            {
                const index: number = state.environments.findIndex(e => e.environmentId === environment.environmentId);
                if (index < 0) {
                    state.environments.push(environment);
                } else {
                    state.environments[index] = environment;
                }
            }
        }

        state.environments = payload;
    },

    [SET_ACTIVE_ENVIRONMENT_ID](state, environmentId: number): void {
        state.activeEnvironmentId = environmentId;
    },

    [GET_SINGLE_USER](state, payload: IUser): void {
        if (payload && !state.users.some(u => u.uniqueId === payload.uniqueId)) {
            state.users.push(payload);
        }
    },

    [GET_USERS](state, payload: IUser[]): void {
        state.users = payload.sortByProperty(x => x.name);
        state.usersLoaded = true;
    },

    [LOGOUT](state): void {
        state.userAccount = null;
    },
};
