import { FormValidations } from "@/constants";
import { RuleDecl } from "vue/types/options";
import { required } from "vuelidate/lib/validators";

const fileSizeValidation = (value, vm) =>  {
    if (value?.file?.size) {
        return value.file.size < FormValidations.FileMaxSize;
    } else {
        return true;
    }
};

const fileNameInvalidCharacters = (value, vm) => {
    if (value?.fileName) {
        const pattern = /^[^"*:<>?/\\]*$/;
        return pattern.test(value.fileName);
    } else {
        return true;
    }
};

export const validations: RuleDecl = {
    vm: {
        developmentDocument: {
            required,
            fileSizeValidation,
            fileNameInvalidCharacters,
        },
    },
};
