import { GetterTree } from "vuex";
import { IAppState } from "./state";
import { IUser, IEnvironment } from "@/models";

const getters: GetterTree<IAppState, any> = {
    activeEnvironment(state: IAppState): IEnvironment {
        return state.environments?.find(e => e.environmentId === state.activeEnvironmentId) ?? null;
    },

    isAuthenticated(state: IAppState): boolean {
        return !!state.userAccount;
    },

    currentUser(state: IAppState): IUser {
        const aadUniqueId: string = state.userAccount?.accountIdentifier;
        return state.users?.find(u => u.uniqueId === aadUniqueId);
    },
};

export { getters };
