import { Component, Vue } from "vue-property-decorator";
import HeaderApp from "@/components/header/Header.vue";
import { IUser } from "@/models";
import { LoaderSpinner } from "@/components";

@Component({
    name: "app",
    components: { HeaderApp, LoaderSpinner },
})
export default class App extends Vue {
    public get isAuthenticated(): boolean {
        return this.$store.getters["app/isAuthenticated"];
    }

    public get appReady(): boolean {
        return this.$store.state.app.appReady;
    }

    public get user(): IUser {
        return this.$store.getters["app/currentUser"];
    }
}
