import Vue from "vue";
import Router from "vue-router";
import { authGuard } from "./AuthGuard";
import { assignmentGuard } from "./AssignmentGuard";
import { requestDevStepIdGuard } from "./RequestDevStepIdGuard";
import { ErrorView, HomeView, NotFoundView, UnauthorizedView, UploadDevelopmentDocument, UploadTechnicalDocument } from "@/views";
import { AssignmentType } from "@/models";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: HomeView,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/requests/:requestId/developmentDocument/:requestDevStepId/",
            name: "UploadDevelopmentDocument",
            component: UploadDevelopmentDocument,
            meta: {
                requiresAuth: true,
                requiredAssignments: [AssignmentType.Administrator, AssignmentType.Supplier, AssignmentType.Repro],
            },
            props: getRequestDevStepIds,
            beforeEnter: requestDevStepIdGuard,
        },
        {
            path: "/requests/:requestId/technicalDocument/:requestDevStepId",
            name: "UploadTechnicalDocument",
            component: UploadTechnicalDocument,
            meta: {
                requiresAuth: true,
                requiredAssignments: [AssignmentType.Administrator, AssignmentType.Supplier],
            },
            props: getRequestDevStepIds,
            beforeEnter: requestDevStepIdGuard,
        },
        {
            path: "/auth",
            redirect: "/",
        },
        {
            path: "/unauthorized",
            name: "unauthorized",
            component: UnauthorizedView,
        },
        {
            path: "/error",
            name: "error",
            component: ErrorView,
            props: true,
        },
        {
            path: "/404",
            name: "not-found",
            component: NotFoundView,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "*",
            redirect: "/404",
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (!to.hash) {
            return { x: 0, y: 0 };
        } else {
            return new Promise((resolve, reject) => {
                setTimeout(() => resolve({ selector: to.hash }), 500);
            });
        }
    },
});

function getRequestDevStepIds(route: any): object {
    return {
        requestId: Number(route.params.requestId),
        requestDevStepId: Number(route.params.requestDevStepId),
    };
}

router.beforeEach(authGuard);
router.beforeEach(assignmentGuard);

export { router };
