import { Component, Vue, Prop } from "vue-property-decorator";
import vSelect from "vue-select";
import { IUserDefinedCode } from "@/models";

@Component({
    name: "user-defined-code-select",
    components: { "vue-select": vSelect },
})
export default class UserDefinedCodeSelect extends Vue {
    @Prop()
    public code: string;

    @Prop()
    public label: string;

    @Prop()
    public userDefinedCodes: IUserDefinedCode[];

    public get model(): IUserDefinedCode | IUserDefinedCode[] {
        if (this.$attrs.multiple){
            return this.userDefinedCodes;
        } else {
            return {
                code: this.code,
                label: this.label,
            };
        }
    }

    public displayOption(code: string, label: string){
        return `${this.trim(code)} - ${this.trim(label)}`;
    }

    public trim(value: string): string {
        return value?.trim() ?? null;
    }

    public update(value: IUserDefinedCode) {
        this.$emit("update:code", value?.code ?? null);
        this.$emit("update:label", value?.label ?? null);
    }
}
