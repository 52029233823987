import { GetterTree } from "vuex";
import { IRequestsState } from "./state";
import { IRequestDevStepExternal } from "@/models";

const getters: GetterTree<IRequestsState, any> = {
    activeDevStep(state: IRequestsState): IRequestDevStepExternal {
        return state.devSteps?.find(r => r.requestDevStepId === state.activeDevStepExternalId) ?? null;
    },
};

export { getters };
