import Vue from "vue";
import { ToastOptions } from "vue-toasted";

export class NotificationService {
    private static readonly options: ToastOptions = {
        duration: 8000,
        position: "bottom-right",
        fitToScreen: true,
        closeOnSwipe: true,
    };

    public static showSuccess(message: string): void {
        Vue.toasted.success(message, NotificationService.options);
    }

    public static showError(message: string): void {
        Vue.toasted.error(message, NotificationService.options);
    }
}
