import { MutationTree } from "vuex";
import { GET_SINGLE_DEV_STEP_EXTERNAL, GET_DEV_STEP_EXTERNALS, SET_ACTIVE_DEV_STEP_EXTERNAL_ID, SET_ACTIVE_PAGE, SET_ITEM_CODE, SET_REQUEST_STATUS_FILTER, RESET_STATE } from "./mutations-types";
import { IRequestsState, getInitialState } from "./state";
import { IRequestDevStepExternal, RequestStatus, IRequestDevStepExternalsPage } from "@/models";

export const mutations: MutationTree<IRequestsState> = {
    [GET_SINGLE_DEV_STEP_EXTERNAL](state, payload: IRequestDevStepExternal): void {
        if (payload) {
            if (!state.devSteps) {
                state.devSteps = [];
            }

            const index: number = state.devSteps.findIndex(r => r.requestId === payload.requestId);
            if (index < 0) {
                state.devSteps.push(payload);
            } else {
                state.devSteps[index] = payload;
            }
        }
    },

    [GET_DEV_STEP_EXTERNALS](state, payload: IRequestDevStepExternalsPage): void {
        state.devStepsLoaded = payload?.devSteps?.length > 0 ?? false;
        state.devSteps = payload?.devSteps ?? [];
        state.limit = payload?.limit ?? 10;
        state.page = payload?.page ?? 0;
        state.totalPages = payload?.totalPages ?? 0;
        state.archives = payload?.archives ?? false;
        state.itemCode = payload?.itemCode;
    },

    [SET_ACTIVE_DEV_STEP_EXTERNAL_ID](state, devStepExternalId: number): void {
        state.activeDevStepExternalId = devStepExternalId;
    },

    [SET_ACTIVE_PAGE](state, page: number): void {
        state.page = page;
    },

    [SET_ITEM_CODE](state, itemCode: string): void {
        state.itemCode = itemCode;
    },

    [SET_REQUEST_STATUS_FILTER](state, requestStatus: RequestStatus): void {
        state.requestStatus = requestStatus;
    },

    [RESET_STATE](state): void{
        Object.assign(state, getInitialState());
    },
};
