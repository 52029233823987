import { Module } from "vuex";
import { IRequestsState, getInitialState } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const requests: Module<IRequestsState, any> = {
    namespaced: true,
    state: getInitialState(),
    getters,
    mutations,
    actions,
};

export { requests };
